// Generated by Framer (1d71865)

import { addFonts, cx, CycleVariantState, SVG, useComponentViewport, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";

const cycleOrder = ["zlkseVY0L"];

const serializationHash = "framer-gO14u"

const variantClassNames = {zlkseVY0L: "framer-v-15ng6t5"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transition1 = {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}

const transformTemplate1 = (_, t) => `translate(-50%, -50%) ${t}`

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion(React.Fragment)

const getProps = ({height, id, width, ...props}) => { return {...props} }

const createLayoutDependency = (props, variants) => {if (props.layoutDependency) return variants.join('-') + props.layoutDependency
return variants.join('-')}

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, ...restProps} = getProps(props)

const {baseVariant, classNames, clearLoadingGesture, gestureHandlers, gestureVariant, isLoading, setGestureState, setVariant, variants} = useVariantState({cycleOrder, defaultVariant: "zlkseVY0L", variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}><Transition value={transition1}><motion.div {...restProps} {...gestureHandlers} className={cx(serializationHash, ...sharedStyleClassNames, "framer-15ng6t5", className, classNames)} data-framer-name={"Variant 1"} layoutDependency={layoutDependency} layoutId={"zlkseVY0L"} ref={ref ?? ref1} style={{...style}}><motion.div className={"framer-nord9f"} data-border data-framer-name={"stroke"} layoutDependency={layoutDependency} layoutId={"pbc7iX9aN"} style={{"--border-bottom-width": "2px", "--border-color": "var(--token-92563ce3-1dfb-430c-bd12-a26e5f9f3e1b, rgb(34, 17, 68))", "--border-left-width": "2px", "--border-right-width": "2px", "--border-style": "solid", "--border-top-width": "2px", borderBottomLeftRadius: "100%", borderBottomRightRadius: "100%", borderTopLeftRadius: "100%", borderTopRightRadius: "100%"}} transformTemplate={transformTemplate1}><SVG className={"framer-rvwtux"} data-framer-name={"stroke"} layout={"position"} layoutDependency={layoutDependency} layoutId={"MeXC6cpRw"} opacity={1} svg={"<svg xmlns=\"http://www.w3.org/2000/svg\" xmlns:xlink=\"http://www.w3.org/1999/xlink\" viewBox=\"0 0 9 10\"><path d=\"M 1.929 1.25 L 1.929 6.875 L 7.071 8.75\" fill=\"transparent\" stroke-width=\"2.25\" stroke=\"var(--token-92563ce3-1dfb-430c-bd12-a26e5f9f3e1b, rgb(34, 17, 68)) /* {&quot;name&quot;:&quot;shadow planet&quot;} */\" stroke-linecap=\"round\" stroke-linejoin=\"round\"></path></svg>"} svgContentId={11360575137} withExternalLayout/></motion.div></motion.div></Transition></Variants>
</LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-gO14u.framer-11l0uac, .framer-gO14u .framer-11l0uac { display: block; }", ".framer-gO14u.framer-15ng6t5 { height: 36px; overflow: visible; position: relative; width: 36px; }", ".framer-gO14u .framer-nord9f { aspect-ratio: 1 / 1; flex: none; height: var(--framer-aspect-ratio-supported, 27px); left: 50%; position: absolute; top: 50%; width: 27px; }", ".framer-gO14u .framer-rvwtux { flex: none; height: 10px; position: absolute; right: 6px; top: 7px; width: 9px; }", ".framer-gO14u[data-border=\"true\"]::after, .framer-gO14u [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 36
 * @framerIntrinsicWidth 36
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]}}}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const FramerVX9whZdC1: React.ComponentType<Props> = withCSS(Component, css, "framer-gO14u") as typeof Component;
export default FramerVX9whZdC1;

FramerVX9whZdC1.displayName = "Icon / Time circle";

FramerVX9whZdC1.defaultProps = {height: 36, width: 36};

addFonts(FramerVX9whZdC1, [{explicitInter: true, fonts: []}], {supportsExplicitInterCodegen: true})